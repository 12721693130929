/* eslint-disable no-undef */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import 'swiper/css/pagination';
import Item from './Item';
import DetailPage from './DetailPage';
import { getMenuVideoDetails } from '../../services/channelData.service';
import { getAllVideoProgress } from '../../utils/localCache.util';
import scrollAppView from '../../utils/viewScroll.util';
import Loading from '../common/Loading';

const HorizontalList = ({
  id,
  title,
  containerId,
  // videosCount,
  type,
  activePage,
  activeSubPage,
  videosList,
  keyUpElement,
  keyDownElement,
}) => {
  // const videosLength = activePage === '' ? videosList.length : videosCount;
  const videoProgress = getAllVideoProgress();
  const scrollHandleButtonId = `scroll-${containerId}`;

  const [dataLoaded, setDataLoaded] = useState(false);
  const [videos, setVideos] = useState(videosList);
  const [showDetailPage, setShowDetailPage] = useState(false);
  const [detailPageData, setDetailPageData] = useState({});
  const [page, setPage] = useState(1);
  const perPage = 10;
  useEffect(() => {
    // if (window.document.querySelectorAll('.page-container')) {
    //   window.document.querySelectorAll(
    //     '.page-container'
    //   )[0].style.marginTop = `119px`;
    //   // window.document.querySelectorAll(
    //   //   '.page-container'
    //   // )[0].style.transition = `all 1s ease-in-out`;
    // }
  }, []);

  useEffect(() => {
    if (activePage === '') {
      setDataLoaded(true);
      return;
    }

    getMenuVideoDetails(activePage, activeSubPage, page, perPage).then(
      (res) => {
        const response = res.content.videos;
        const videosData = [];
        response.forEach((v) => {
          videosData.push({
            id: v._id,
            title: v.title,
            description: v.description || '',
            shortDescription: v.short_description || '',
            hlsUrl: v.hls_url,
            poster: v.poster,
            posterH: v.poster_16_9,
            posterV: v.poster_9_16,
            startTime: v.start_date_time || '',
            endTime: v.end_date_time || '',
            duration: Number(v.duration || 0),
            genres: v.genres || '',
            category: v.category || '',
            channelId: Number(v.channel_id),
            director: v.director || '',
            actor1: v.actor1 || '',
            actor2: v.actor2 || '',
            actor3: v.actor3 || '',
            rating: v.rating || '',
            ratingSource: v.rating_source || '',
            releaseDate: v.releaseDate || '',
            season: Number(v.season),
            episode: Number(v.episode),
            srtUrl: v.srt_url || '',
            vttUrl: v.vtt_url || '',
            source: v.source || '',
            playDirectUrl: v.playDirectUrl || '',
            liveVastUrl: v.liveVastUrl || '',
            type,
          });
        });
        setVideos((data) => [...data, ...videosData]);
        setDataLoaded(true);
      }
    );
  }, [activeSubPage, page]);
  // console.log(videos, 'vvvvvvvvvvvvvvvv');
  const handleShowDetailPage = (data) => {
    setShowDetailPage(false);
    setDetailPageData(data);
    setShowDetailPage(true);
    if (window.document.getElementById('resume-btn')) {
      window.document.getElementById('resume-btn').classList.add('focused');
    } else if (window.document.getElementById('play-btn')) {
      window.document.getElementById('play-btn').classList.add('focused');
    }
  };
  const handleHideDetailPage = () => {
    setShowDetailPage(false);
    // window.scrollTo(0, Number(scrollValGet));
  };

  const handleScroll = () => {
    const focusedElements = window.document.querySelectorAll(
      '.prj-element.focused'
    );
    if (focusedElements.length > 0) {
      scrollAppView(focusedElements[0]);
      if (window.document.querySelectorAll('.page-container')) {
        window.document.querySelectorAll(
          '.page-container'
        )[0].style.marginTop = `107px`;
        // setTimeout(() => {
        //   window.document.querySelectorAll(
        //     '.page-container'
        //   )[0].style.transition = `all 1s ease-in-out`;
        // }, 2000);
        // window.document.querySelectorAll(
        //   '.page-container'
        // )[0].style.transition = `all 1s ease-in-out`;
      }
      const currentFocus =
        focusedElements[0].dataset.focusPagination.split('-')[0];
      if (Number(currentFocus) === videos.length - 9) {
        setPage(page + 1);
      }
    }
  };

  if (!dataLoaded) {
    return <Loading />;
  }

  return (
    <>
      {videos.length > 0 && (
        <div className="horizontal-list" id={containerId} role="none">
          <button
            type="button"
            className="hide"
            id={scrollHandleButtonId}
            onClick={handleScroll}
          >
            Scroll
          </button>
          <div className="grid-title" id={type}>
            {title}
          </div>
          <Swiper
            onBeforeInit={() => setPage(page + 1)}
            onNavigationNext={() => setPage(page + 1)}
            className="mySwiper"
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            slidesPerGroup={type === 'movies' ? 5 : 4}
            // slidesOffsetAfter={detailPageData.isPortrait ? -900 : -800}
            navigation
            breakpoints={{
              576: {
                slidesPerView: type === 'movies' ? 1 : 1,
                spaceBetween: 10,
              },
              640: {
                slidesPerView: type === 'movies' ? 2 : 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: type === 'movies' ? 4 : 3,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: type === 'movies' ? 7 : 4,
                spaceBetween: 20,
              },
            }}
            onMouseOver={() => console.log('mouse hover**')}
            id={type}
          >
            <div className={`media-scroller ${type}`}>
              {videos.map((v, idx) => (
                <>
                  <SwiperSlide
                    key={`media-scroller${id}v${v.id}${Math.random(1000)}`}
                  >
                    <Item
                      key={`${id}v${v.id}`}
                      id={`item${id}v${v.id}`}
                      videoId={v.id}
                      title={v.title}
                      description={v.description}
                      shortDescription={v.shortDescription}
                      hlsUrl={v.hlsUrl}
                      poster={v.poster}
                      posterH={v.posterH}
                      posterV={v.posterV}
                      startTime={v.startTime}
                      endTime={v.endTime}
                      duration={v.duration}
                      progress={Number(videoProgress[v.id] || 0)}
                      genres={v.genres}
                      category={v.category}
                      channelId={v.channelId}
                      director={v.director}
                      actor1={v.actor1}
                      actor2={v.actor2}
                      actor3={v.actor3}
                      rating={v.rating}
                      ratingSource={v.ratingSource}
                      releaseDate={v.releaseDate}
                      season={v.season}
                      episode={v.episode}
                      srtUrl={v.srtUrl}
                      vttUrl={v.vttUrl}
                      source={v.source}
                      playDirectUrl={v.playDirectUrl}
                      liveVastUrl={v.liveVastUrl}
                      type={type}
                      dataFocusLeft={
                        idx === 0
                          ? '.side-nav .prj-element.active'
                          : `#item${id}v${videos[idx - 1].id}`
                      }
                      dataFocusRight={
                        idx + 1 === videos.length
                          ? ''
                          : `#item${id}v${videos[idx + 1].id}`
                      }
                      dataFocusUp={keyUpElement || ''}
                      dataFocusDown={keyDownElement || ''}
                      dataOnSelfFocus={`#${scrollHandleButtonId}`}
                      handleShowDetailPage={handleShowDetailPage}
                      dataOnPagination={`${idx}-${containerId}`}
                    />
                  </SwiperSlide>
                  <div className="extra-margin-div" />
                </>
              ))}
            </div>
          </Swiper>
        </div>
      )}

      {showDetailPage && (
        <DetailPage
          detailPageData={detailPageData}
          handleHideDetailPage={handleHideDetailPage}
          videos={videos}
          containerIdPrefix={`item${id}v`}
          setShowDetailPage={setShowDetailPage}
        />
      )}
    </>
  );
};

HorizontalList.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  containerId: PropTypes.string.isRequired,
  // videosCount: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  activePage: PropTypes.string.isRequired,
  activeSubPage: PropTypes.string.isRequired,
  videosList: PropTypes.arrayOf(PropTypes.shape()),
  keyUpElement: PropTypes.string.isRequired,
  keyDownElement: PropTypes.string.isRequired,
};
HorizontalList.defaultProps = {
  videosList: [],
};

export default HorizontalList;
