/* eslint-disable import/no-duplicates */
import landingImage from '../assets/images/disabilityownedwebsitestillsandscreenshot/shaking-hands-with-disabled-businessman-in-wheelchair-outdoor-in-SBV-337819973-HD0 (1).png';
import sectionOneImage from '../assets/images/disabilityownedwebsitestillsandscreenshot/Biz-Ability 1280x720-4.png';
import sectionTwoImage from '../assets/images/disabilityownedwebsitestillsandscreenshot/DMV BUSINESS SPOTLIGHT 1280X720.png';
import sectionThreeImage from '../assets/images/disabilityownedwebsitestillsandscreenshot/SMALL BUSINESS CAPITAL 1280X720.png';
import sectionFourImage from '../assets/images/disabilityownedwebsitestillsandscreenshot/DISABILITY OWNED CINEMA 1280X720.png';
import sectionFiveImage from '../assets/images/disabilityownedwebsitestillsandscreenshot/SMALL BUSINESS CAPITAL 1280X720.png';
import sectionSixImage from '../assets/images/disabilityownedwebsitestillsandscreenshot/NDI FRC 1280x720-2.png';
import sectionSevenImage from '../assets/images/disabilityownedwebsitestillsandscreenshot/2GI 1280X720-2.png';
import sectionEightImage from '../assets/images/disabilityownedwebsitestillsandscreenshot/SMALL BUSINESS CAPITAL 1280X720.png';
import sectionNineImage from '../assets/images/disabilityownedwebsitestillsandscreenshot/NDI FRC 1280x720-2.png';
import sectionTenImage from '../assets/images/disabilityownedwebsitestillsandscreenshot/WEBINARS 1280X720.png';
import sectionElevenImage from '../assets/images/disabilityownedwebsitestillsandscreenshot/DISABILITY OWNED CINEMA 1280X720.png';

const LandingPageData = {
  topSection: {
    heading: 'Disability Owned TV',
    // sub_heading: 'Join today.',
    description: 'The Business Channel for People with Disabilities',
    button_text: 'Watch Now',
    bg_img: landingImage,
  },
  section_one: {
    heading: 'Nikki Powis Interviews Savvy Business Owners with Disabilities ',
    // description: 'Biz- Ability with Nikki Powis',
    image: sectionOneImage,
  },
  section_two: {
    heading:
      'DMV Spotlight Shines A Light on DMV Business Owners with Disabilities ',
    // description: 'DMV Spotlight ',
    image: sectionTwoImage,
  },
  section_three: {
    heading:
      'US SBA Videos provides information to plan, start, and grow a business.',
    // description: 'US Small Business Administration ',
    image: sectionThreeImage,
  },
  section_four: {
    heading:
      'National Disabilitiy Institute focuses on the financial health and well-being of people with disabilities.',
    // description:
    //   'Send children on adventures with their favourite characters in a space made just for them—free with your membership.',
    image: sectionFourImage,
  },
  section_five: {
    heading: `Small Business Start Ups Features SBA Funded Success Stories`,
    // description:
    //   'Send children on adventures with their favourite characters in a space made just for them—free with your membership.',
    image: sectionFiveImage,
  },
  section_six: {
    heading: `Get Money Matters Insights From NDI's  Financial Resilience Center `,
    // description:
    //   'Send children on adventures with their favourite characters in a space made just for them—free with your membership.',
    image: sectionSixImage,
  },
  section_seven: {
    heading: `Meet 2Gether International's Founders with Disabilities`,
    // description:
    //   'Send children on adventures with their favourite characters in a space made just for them—free with your membership.',
    image: sectionSevenImage,
  },
  section_eight: {
    heading: 'Small Business Captial - Tips On How To Fund Your Business',
    // description:
    //   'Send children on adventures with their favourite characters in a space made just for them—free with your membership.',
    image: sectionEightImage,
  },
  section_nine: {
    heading: `Get Money Matters Insights From NDI's...`,
    // description:
    //   'Send children on adventures with their favourite characters in a space made just for them—free with your membership.',
    image: sectionNineImage,
  },
  section_ten: {
    heading: `Watch NDI's 2022 Small Busines Confernece Sessions `,
    // description:
    //   'Send children on adventures with their favourite characters in a space made just for them—free with your membership.',
    image: sectionTenImage,
  },
  section_eleven: {
    heading: 'Disability Owned Cinema features Empowering Films and Docs',
    // description:
    //   'Send children on adventures with their favourite characters in a space made just for them—free with your membership.',
    image: sectionElevenImage,
  },
  faq: {
    main_title: 'Frequently Asked Questions',
    question_answer: [
      {
        question: 'What is Disabilty Owned?',
        answer:
          'Disability Owned is the FREE business education and information streaming service for people with disabilities.  Watch original shows, educational videos, and inspiring profiles, that can help people with disabilities plan, start, and grow a business enterprise. ',
      },
      {
        question: 'How do I get started?',
        answer:
          'Watch Disability Owned for FREE on the web and on your Roku, Apple TV, Amazon Fire TV, iOS and Android devices. ',
      },
      {
        question: 'Where can I watch?',
        answer:
          'Disability Owned is FREE to watch on your laptop,Roku TV, Apple TV, Amazon Fire TV, iOS and Android device. ',
      },
      {
        question: 'What can I watch on Disability Owned?',
        answer:
          'Disability Owned has an orginal shows, educational video, and inspiring profiles, and more. Watch for FREE on anytime on your favorite streaming device.',
      },
      {
        question: 'Is Disability Owned Good For Everyone ',
        answer:
          'Disability Owned offer business education, information, and inspiration everyone can enjoy, even those without a disability or interest in being self-employed.',
      },
    ],
  },
  about_us: {
    heading: 'About Us',
    description: `Disability Owned is a FREE business streaming service for people with disabilities. Watch Disability Owned is available to watch on your laptop, Roku TV, Apple TV, Amazon Fire TV, iOS or Android devices. Boston Media, LLC creators and managers of Disability Owned, is a member of the National Disability Institute's Community Navigator Pilot Program (CNPP). NDI's CNPP is funded in part through a grant from the U.S. Small Business Administrtion.`,
  },
  copyRight: 'Copyright © 2023 All rights reserved',
};

export default LandingPageData;
