export const APP_PAGES = {
  LANDINGPAGE: 'landingpage',
  HOME: 'home',
  SETTINGS: 'settings',
  SEARCH: 'search',
};

export const PAGE_LAYOUT = {
  RAIL: 'rail',
  GRID: 'grid',
};

export const VIDEO_TYPES = {
  EVENT: 'event',
  VIDEO: 'video',
  MOVIES: 'movies',
};

export const PLATFORMS = {
  WEB: 'Web',
  // FIRETV: 'FireTV',
  // SAMSUNG: 'Samsung',
  // LG: 'LG',
};

export const VIDEO_PROGRESS_KEY = '__u_userProgress';
export const CLIENT_IP = '__u_ip';
