import React, { useState, useEffect } from 'react';
import { APP_PAGES, PAGE_LAYOUT, PLATFORMS } from './config/const.config';
import SplashScreenPage from './views/SplashScreen';
import HomePage from './views/Home';
import SearchPage from './views/Search';
import SettingsPage from './views/Settings';
import { getMainMenuData } from './services/channelData.service';
import './utils/webFocusHandler.util';
import './assets/styles/chota.css';
import './assets/styles/base.css';
import './assets/styles/web.css';
import LandingPage from './components/pages/LandingPage';
import {
  addLocalStorageData,
  getLocalStorageData,
} from './utils/localCache.util';

const App = () => {
  const [appLoaded, setAppLoaded] = useState(false);
  const [menuData, setMenuData] = useState([]);
  const [pageLayouts, setPageLayouts] = useState({
    layout: PAGE_LAYOUT.GRID,
    bgVideo: '',
  });
  const [activePage, setActivePage] = useState(APP_PAGES.LANDINGPAGE);
  const [activePageLayout, setActivePageLayout] = useState({
    layout: PAGE_LAYOUT.GRID,
    bgVideo: '',
  });

  useEffect(() => {
    getMainMenuData()
      .then((res) => {
        const menu = [];
        const layouts = {};
        res.content.top_menus.forEach((m) => {
          menu.push({
            id: m._id,
            title: m.title,
          });

          layouts[m._id] = {
            layout: m.playlist_layout,
            bgVideo: '',
          };
          if (
            Number(m.live_video_in_background) === 1 &&
            m.live_video_link !== ''
          ) {
            layouts[m._id].bgVideo = m.live_video_link;
          }
        });

        setMenuData(menu);
        setPageLayouts(layouts);
        if (PLATFORMS.FIRETV || PLATFORMS.SAMSUNG || PLATFORMS.LG) {
          setActivePage(menu[0].id.toString());
        }
        const getLocalData = getLocalStorageData('pageClick');
        // if (getLocalData === 'landingpage') {
        //   setActivePage(APP_PAGES.LANDINGPAGE);
        // }
        if (getLocalData) {
          setActivePage(menu[0].id.toString());
        }
        setActivePageLayout(layouts[menu[0].id]);

        setTimeout(() => {
          setAppLoaded(true);
        }, 1000);
      })
      .catch(() => {});
  }, []);

  const handlePageChange = (page) => {
    addLocalStorageData('pageClick', page);

    window.scrollTo(0, 0);
    if (pageLayouts[Number(page)]) {
      setActivePageLayout(pageLayouts[Number(page)]);
    } else {
      setActivePageLayout(PAGE_LAYOUT.GRID);
    }
    setActivePage(page.toString());
  };

  if (!appLoaded) {
    return <SplashScreenPage />;
  }
  const landingPageButton = () => {
    handlePageChange(menuData[0].id.toString());
  };

  switch (activePage) {
    case APP_PAGES.SEARCH:
      return (
        <SearchPage
          menuData={menuData}
          activePage={activePage}
          handlePageChange={handlePageChange}
        />
      );

    case APP_PAGES.SETTINGS:
      return (
        <SettingsPage
          menuData={menuData}
          activePage={activePage}
          handlePageChange={handlePageChange}
        />
      );

    case APP_PAGES.LANDINGPAGE:
      // for only web application
      return (
        <LandingPage
          menuData={menuData}
          activePage={activePage}
          activePageLayout={activePageLayout}
          handlePageChange={handlePageChange}
          landingPageButton={landingPageButton}
        />
      );

    default:
      return (
        <HomePage
          menuData={menuData}
          activePage={activePage}
          activePageLayout={activePageLayout}
          handlePageChange={handlePageChange}
        />
      );
  }
};

export default App;
